// five hundred

$charecter4: 443;
$scene: 1182;

.five00wrap {
  min-height: 100%;
  background: #f7f7f7;
}

.scene-3 {
  display: block;
  margin: 0 auto;
  max-width: 1180px;
  position: relative;
  img {
    max-width: 100%;
  }
}

.charecter-4 {
  position: absolute;
  left: 34.66101694915254%;
  bottom: 0;
  width: percentage($charecter4 / $scene);
}

.messge500 {
  @extend .text-center;
  h1 {
    font-family: 'Open Sans', sans-serif;
    margin-top: 25px;
    color: #8d8988;
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    @include respond-to("md") {
      font-size: 50px;
      line-height: 63px;
    }

    span {
      font-size: 40px;
      color: #f1734c;
      font-weight: 300;
      @include respond-to("md") {
        font-size: 80px;
      }
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
    color: #9c9694;
    max-width: 620px;
    padding: 0 20px;
    margin: 0 auto;
    display: block;
  }
  @media screen and (min-width: 1200px) {
    h1 {
      margin-top: 86px;
      margin-bottom: 30px;
      font-size: 81px;
      span {
        font-size: 105px;
      }
    }
  }
}

.hand-part1 {
  width: percentage(94 / $charecter4);
  position: absolute;
  right: percentage(41 / $charecter4);
  top: percentage(200 / 487);
  transform-origin: 90% 100%;
  animation: hand 3s linear 0s infinite;
  img {
    max-width: 100%;
  }
}

.eye {
  width: percentage(149 / $charecter4);
  position: absolute;
  right: percentage(51 / $charecter4);
  top: percentage(100 / $charecter4);
  height: 54px;
}

@keyframes hand {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-16deg);
  }
  40% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


// five hundred end