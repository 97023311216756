.landingpage-wrap {
  padding-top: 50px;
  background: url(../images/landing/background.jpg) no-repeat center;
  background-size: cover;
  min-height: 100%;
  @include respond-to("md") {
    padding-top: 200px;
  }

  aside {
    @include make-md-column(3);
    h1 {
      font-size: 30px;
      color: #989ea1;
      font-weight: 300;
      strong {
        color: #c4cacd;
        font-weight: 600;
      }
    }
    p {
      font-size: 18px;
      line-height: 25px;
      color: #999fa1;
      margin-top: 40px;
      font-weight: 300;
      font-family: 'Open Sans', sans-serif;
    }
  }
  main {
    @include make-md-column(9);
  }
  .page-list {
    @include make-row;
    figure {
      padding-top: 15px;
      padding-bottom: 15px;
      @include make-xs-column(6);
      @include make-sm-column(6);
      @include make-md-column(6);
      a {
        display: block;
        overflow: hidden;
        border-radius: 5px;
        //max-width: 259px;
     
      }
      img {
        max-width: 100%;
        height: auto;
        transition: all .5s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  footer {
    padding-top: 50px;
    padding-bottom: 50px;
    @include make-xs-column(12);
    p {
      font-size: 14px;
      color: #8598a1;
      .user {
        color: #b9b9b9;
      }
    }
    a {
      color: #8598a1;
      &:hover, a:focus {
        color: #fff;
        text-decoration: none;
      }
    }
    @include respond-to("md") {
      padding-top: 200px;
      padding-bottom: 200px;
    }
  }
}